.experience-top-div {
  height: 900px;
  padding: 20px 0;
}

.experience-container {
  margin: 0 5%;
  justify-content: center;
}
.middle-line-and-arrow {
  height: 795px;
  margin: 0 25px;
  position: relative;
}
.exp-line {
  height: 780px;
  width: 40px;
  border-radius: 10px;
  background-color: #ff7d00;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  align-items: center;
  color: white;
  font-weight: 700;
}
.exp-arrow {
  color: #ff7d00;
  position: absolute;
}
.first-arrow {
  top: 10px;
  left: -22px;
  transform: rotate(180deg);
  font-size: 1.5rem;
}
.second-arrow {
  top: 221px;
  right: -22px;
  font-size: 1.5rem;
}
.third-arrow {
  top: 398px;
  left: -22px;
  transform: rotate(180deg);
  font-size: 1.5rem;
}
.fourth-arrow {
  top: 575px;
  right: -22px;
  font-size: 1.5rem;
}
.experience-container .card {
  width: 400px;
  height: 210px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.5s ease-in-out;
  color: gray;
}
.exp-left-cards .card:last-child {
  margin-top: 178px;
}
.exp-right-cards .card:first-child {
  margin-top: 210px;
}
.exp-right-cards .card:last-child {
  margin-top: 146px;
}
.experience-container .card-header {
  background-color: #ff7d00;
  color: white;
  border-radius: 5px 5px 0 0;
}
.experience-container .exp-date {
  padding: 5px;
  text-align: center;
  background-color: #33bbc5;
  color: white;
  font-size: 0.8rem;
  border-radius: 0 0 5px 5px;
  font-weight: 600;
}

.experience-container .card-hover {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.511);
  height: 210px;
  position: absolute;
  border-radius: 5px;
  transition: 0.6s;
}
.card-hover:hover {
  transform: scale(1.05, 1.1);
  border: 6px solid #33bbc5;
  background-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 992px) {
  .experience-container .card {
    width: 100%;
    height: 270px;
  }
  .exp-right-cards .card:last-child {
    margin-top: 83px;
  }
  .exp-right-cards .card:first-child {
    margin-top: 213px;
  }
  .experience-container .card-hover {
    height: 270px;
  }
}
@media only screen and (max-width: 750px) {
  .experience-container .card {
    width: 100%;
    /* height: 300px; */
  }
  .experience-container .card-hover {
    /* height: 300px; */
  }
}

@media only screen and (max-width: 600px) {
  .experience-top-div {
    height: 1370px;
  }

  .experience-container {
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
  }
  .experience-container .card {
    /* height: 270px; */
  }
  .experience-container .card-hover {
    /* height: 270px; */
  }

  .exp-left-cards,
  .exp-right-cards {
    margin-left: 70px;
  }
  .middle-line-and-arrow {
    position: absolute;
    left: 0;
    height: 1300px;
  }
  .first-arrow {
    top: 10px;
    right: -22px;
    left: 44px;
    transform: rotate(0deg);
  }
  .second-arrow {
    top: 350px;
  }
  .third-arrow {
    top: 694px;
    right: -22px;
    left: 44px;
    transform: rotate(0deg);
  }
  .fourth-arrow {
    top: 1038px;
  }
  .middle-line-and-arrow {
    margin: 0 25px 0 0;
  }
  .exp-left-cards .card:last-child {
    margin-top: 60px;
  }
  .exp-right-cards .card:first-child {
    margin-top: 70px;
  }
  .exp-right-cards .card:last-child {
    margin-top: 74px;
  }
  .exp-line {
    height: 1300px;
  }
}
