.carousel-indicators {
  bottom: -25px !important;
}
.carousel-inner {
  background-color: #004046;
  min-height: 500px;
}
.carousel-control-next,
.carousel-control-prev {
  width: 3% !important;
}

.carousel-item {
  padding: 30px;
}
