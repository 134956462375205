.swiper {
  padding: 40px !important;
}

.my-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #004046;
  /* padding: 8px 12px; */
  border-radius: 10px;
  color: #faefe0;
  box-shadow: #000 0px 3px 8px;
  /* transition: all ease 0.4s; */
  max-width: 330px;
  min-height: 410px;
}

.my-card:hover {
  box-shadow: 0 2px 6px #f8f9fa;
}

.card-left-content {
  display: flex;
  flex-direction: column;
}

.card-img {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 8px 8px 0 0;
}

.line-and-cirkle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.one,
.two {
  display: flex;
  width: 35%;
  align-items: center;
  justify-content: center;
}

.line {
  height: 2px;
  width: 90%;
  background-color: #ff7d00;
  border-radius: 5px;
}

.circle {
  height: 9px;
  width: 9px;
  background-color: #ff7d00;
  border-radius: 50%;
}

.l-circle {
  margin-left: -3px;
}

.r-circle {
  margin-right: -3px;
}

.card-right-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 12px 12px 12px;
}

.project-info {
  text-align: justify;
  font-size: 0.8rem;
}
.see-demo,
.go-github {
  border-radius: 10px;
  border: none;
  padding: 0.3rem 1rem;
  width: calc(50% - 6px);
  font-size: 0.7rem;
  text-align: center;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.469) 0px -4px 18px;
}
.see-demo {
  /* border: 2px solid #33bbc5; */
  color: #33bbc5;
}
.go-github {
  /* border: 2px solid #ff7d00; */
  color: #ff7d00;
}

@media only screen and (max-width: 600px) {
}
