.link-id-tag {
  height: 206px;
}

@media only screen and (max-width: 600px) {
  .section-header {
    text-align: center;
  }
  .link-id-tag {
    height: 110px;
  }
}

.my-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background-color: #ff7d00;
  transform-origin: 0%;
  z-index: 99999;
}

.section-header {
  padding: 0 5rem;
  color: #33bbc5;
}

.title-icon-image {
  top: 6px;
  left: 0;
}
.frelance-text {
  padding: 0 5rem;
}

@media only screen and (max-width: 600px) {
  .frelance-text {
    padding: 0 3rem;
  }
}
