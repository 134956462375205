@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.animation {
  position: relative;
  z-index: 0;
  border-radius: 29px;
  overflow: hidden;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-weight: bold;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    border-radius: 25px;
    background-color: rgb(9, 174, 192);
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#fe0920, #fe0920, #f645fa, #0d6efd);
    animation: rotate 8s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    /* background: white; */
    border-radius: 25px;

    background: #004046;
  }
}

/* Popup arka plan stili */
.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 64, 70, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup kutusu stili */
.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;
  /* width: 90%; */
  width: 541px;
  height: 451px;
  border-radius: 16px;
  position: relative;
  text-align: center;
}

.projects {
  position: absolute;
  right: 50px;
  top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  z-index: 23;

  img {
    width: 140px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      transform: scale(1.1);
    }
  }
}

/* Kapatma butonu stili */
.close-button {
  position: absolute;
  top: 3px;
  right: 14px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ff7d00;
  z-index: 22;
}

.hire-me-img,
.hire-me-img-mobil {
  width: 560px;
  height: auto;
  position: absolute;
  top: -13px;
  left: -9px;
  border-radius: 70px;
  z-index: 20;
}

/* İçerik stili */
.popup-content {
  font-size: 1.2rem;
  color: #faefe0;
  z-index: 22;
  h2 {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}

/* Buton stili */
.hire-me-button {
  display: inline-block;
  /* background: #ff7d00; */
  color: #faefe0;
  padding: 6px 0.7rem;
  border-radius: 6px;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 1.5rem;
  margin-right: 13rem;
  box-shadow: 0 4px 10px #0000005c;
  transition: transform 0.2s ease-in-out;

  img {
    width: 80px;
    height: 30px;
  }
}

/* Hover durumu */
.hire-me-button:hover {
  transform: scale(1.05);
}

/* Responsive stili */
@media (max-width: 768px) {
  .popup-container {
    padding: 1.5rem;
  }
}

@media (min-width: 600px) {
  .hire-me-img-mobil {
    display: none;
  }
}
@media (max-width: 600px) {
  .hire-me-img-mobil {
    display: block;
    top: -20px;
    left: -18px;
    width: 394px;
  }
  .hire-me-img {
    display: none;
  }
  .popup-container {
    width: 357px;
    height: 298px;
  }
  .close-button {
    top: 0px;
    right: 9px;
  }
  .projects {
    top: 40px;
    right: 40px;
    img {
      width: 97px;
    }
  }
  .hire-me-button {
    margin-bottom: 0.5rem;
    margin-right: 9rem;
    img {
      width: 60px;
      height: 20px;
    }
  }
}
