.footer {
  background-color: #33bbc5;
  color: #faefe0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  position: relative;
}

.footer-left-side,
.footer-right-side {
  flex: 1;
}
.footer-left-side {
  font-size: 0.7rem;
  text-align: center;
}
.vertical-line {
  height: 100px;
  border-left: 3px solid #faefe0;
}

.footer-right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.footer-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  width: 90px;
  font-size: 0.8rem;
}
.footer-link:hover {
  color: #004046;
  font-weight: 600;
  transform: scale(1.2);
  cursor: pointer;
}
.back-top {
  color: #faefe0;
  background-color: #ff000000;
  text-decoration: none;
  position: absolute;
  bottom: 200px;
  right: 3%;
  width: 96px;
  height: 63px;
  transform: rotate(270deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ff7d00;
  border-radius: 0 10px 10px 0;
  transition: 0.6s;
}
.back-top i {
  font-size: 2rem;
  color: #ff7d00;
}
.back-top:hover {
  border: 2px solid #fff;
  color: #ff7d00;
}
.back-top:hover .fa-chevron-right {
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .back-top {
    height: 46px;
    font-size: 0.9rem;
    right: -1%;
    width: 80px;
  }
  .back-top i {
    font-size: 1.3rem;
  }
}
