.free-container {
  max-width: 1000px;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.free-card {
  position: relative;
  margin: 20px 0;
  margin-top: 40px;
  width: 300px;
  height: 400px;
  transform-style: preserve-3d;
  transform: perspective(2000px);
  transition: 1s;
  border-radius: 12px;
  border: 2px solid #32bbc5;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.free-card:hover {
  /* z-index: 1111; */
  transform: perspective(2000px) rotate(-10deg);
  box-shadow: inset 20px 0 50px #00000052;
}

.free-card .hover-me-text {
  position: absolute;
  top: -30px;
  left: 37%;
  color: #32bbc5;
}

.free-card .free-img-container {
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: left;
  z-index: 2;
  transition: 1s;
  /* border: 1px solid #ff7d00; */
}

.free-card .free-img-container img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.free-card:hover .free-img-container {
  transform: rotateY(-135deg);
}

.free-card .free-card-details {
  position: absolute;
  left: 7px;
  top: 7px;
  padding: 10px 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96.4%;
  width: 95%;
}

.free-card .free-card-details h2 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 2em;
  color: #ff7d00;
}

.free-card .free-card-details p {
  margin: 0;
  padding: 0;
  line-height: 22px;
  font-size: 0.9em;
  color: #faefe0;
}
