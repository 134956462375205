.about-margin-top {
  margin-top: 106px;
}
.about-container {
  min-height: 500px;
  width: 90%;
  margin: 30px auto 20px auto;
  background-color: #004046;
  color: white;
  display: flex;
  justify-content: space-between;
}
/* Touch Me area css*/
.touch-me-container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 14px;
}
.me,
.touch {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  font-size: 0.8rem;
  color: #ff7d00;
  font-weight: 700;
}
.line-1,
.line-2 {
  width: 2px;
  height: 65px;
  background-color: #ff7d00;
  border-radius: 4px;
  margin-left: 7px;
}
.line-1 {
  margin-bottom: -2px;
}
.line-2 {
  margin-top: -2px;
}
.circle-1,
.circle-2 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ff7d00;
}
.me {
  margin-top: 20px;
}
.touch {
  margin-bottom: 20px;
}
.github-icon a,
.linkedin-icon a {
  display: inline-block;
  color: #ff7d00;
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 0.6s;
}

.github-icon a:hover,
.linkedin-icon a:hover {
  color: white;
  transform: scale(1.5);
}

/*** Touch Me area css AND ****/

/* About me info area css */
.about-me-text-container {
  height: 500px;
  font-family: "Open Sans";
  font-size: 0.9rem;
  font-weight: 100;
  letter-spacing: 1px;
}
.my-fullname {
  font-weight: 100;
  font-size: 4rem;
  border-bottom: 2px solid #ff7d00;
}

.my-fullname {
  padding: 14px;
  background: linear-gradient(to right, #fff 0, #fff 10%, #ff7d00 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 600px;
  }
}

.about-me-text-container span {
  font-weight: 500;
}

/**** About me info area css AND ****/

.my-img-container {
  height: 500px;
  flex-grow: 1;
  max-width: 550px;
}
.about-img {
  width: 100%;
  margin-top: 10px;
  filter: drop-shadow(-2px 5px 6px #00000082);
}
@media only screen and (min-width: 1124px) {
  .my-fullname {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 992px) {
  .my-fullname {
    font-size: 2.6rem;
  }
  .about-container {
    position: relative;
  }
  .touch-me-container {
    position: absolute;
    top: 113%;
    flex-direction: row;
    width: 100%;
    align-items: center;
    height: 40px;
    justify-content: center;
  }
  .line-1,
  .line-2 {
    width: 65px;
    height: 2px;
    border-radius: 4px;
    margin-left: 7px;
  }
  .line-1 {
    margin: 0 -1px 0 5px;
  }
  .line-2 {
    margin: 0 5px 0 -1px;
  }
  .circle-1,
  .circle-2 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  .github-icon,
  .linkedin-icon {
    margin: 0 30px;
  }
  .linkedin-icon {
    margin-top: 15px;
  }
  .me,
  .touch {
    writing-mode: horizontal-tb;
    text-orientation: mixed;
    font-size: 0.8rem;
    color: #ff7d00;
    font-weight: 700;
    margin: 0 10px;
  }
}
@media only screen and (max-width: 768px) {
  .my-fullname {
    font-size: 3.5rem;
  }
  .about-me-text {
    font-size: 0.8rem;
  }
  .about-me-text-container,
  .my-img-container {
    height: auto;
    margin: auto;
  }
  .my-img-container {
    text-align: center;
  }
  .about-img {
    width: 77%;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .about-container {
    flex-direction: column;
  }
  .about-me-text-container {
    height: auto;
  }
  .my-fullname {
    font-size: 2.8rem;
    padding: 4px;
  }
  .touch-me-container {
    top: 103%;
  }
  .touch {
    margin-right: 4px;
  }
  .me {
    margin-right: 4px;
  }
  .github-icon,
  .linkedin-icon {
    margin: 0 10px;
    margin-top: 15px;
  }
  .about-margin-top {
    margin-top: 106px;
  }
}
